import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

window.swiperInit = function () {
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 5,
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      620: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 5,
      }
    }
  });
}

document.addEventListener("DOMContentLoaded", function(event) {
  swiperInit()
});

window.addEventListener("jsRendered", function(event) {
  swiperInit()
});
