window.onscroll = function(){stickyMenu()};

function stickyMenu() {
  const topBars = document.querySelectorAll(".js-sticky-header");
  const topBarOffset = document.querySelector(".js-sticky-header-offset");
  const stickyClass = 'header-sticked';

  if (window.scrollY > topBarOffset.clientHeight) {
    for (let a = 0; a < topBars.length; a++) {
      topBars[a].classList.add(stickyClass);
    }
  } else {
    for (let i = 0; i < topBars.length; i++) {
      topBars[i].classList.remove(stickyClass);
    }
  }
}