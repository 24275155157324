yandexMetrikaEvent = function (element) {
  const counter = '71849125';
  const goal = element.dataset.metrikaGoal;
  ym(counter, 'reachGoal', goal);
  return true;
}

document.addEventListener("yacounter71849125inited", function() {
  const list = document.querySelectorAll('.js-yandex-metrika');
  list.forEach(function(item) {
    item.addEventListener('click', () => {
      yandexMetrikaEvent(item);
    });
  });
})