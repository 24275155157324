import noUiSlider from 'nouislider';

document.addEventListener("DOMContentLoaded", function(event) {
  const slider = document.querySelector(".js-price-slider");
  const minPriceInput = document.querySelector(".js-min-price-input");
  const maxPriceInput = document.querySelector(".js-max-price-input");

  if (slider !== null || minPriceInput !== null || maxPriceInput !== null) {
    let minPrice = parseInt(slider.dataset.min_price);
    const maxPrice = parseInt(slider.dataset.max_price);
    let startPrice = parseInt(slider.dataset.start_price);
    const endPrice = parseInt(slider.dataset.end_price);

    if (minPrice === maxPrice) { minPrice = minPrice - 1 }
    if (startPrice === endPrice) { startPrice = startPrice - 1 }

    noUiSlider.create(slider, {
      start: [startPrice, endPrice],
      connect: true,
      range: {
        'min': minPrice,
        'max': maxPrice
      },
      pips: {
        mode: 'values',
        values: [minPrice, (minPrice + maxPrice) / 2, maxPrice],
        density: 1000
      }
    });

    slider.noUiSlider.on('update', function (values, handle) {
      minPriceInput.value = parseInt(values[0]);
      maxPriceInput.value = parseInt(values[1]);
    })

    minPriceInput.addEventListener('change', function () {
      slider.noUiSlider.set([this.value, null]);
    });

    maxPriceInput.addEventListener('change', function () {
      slider.noUiSlider.set([null, this.value]);
    });
  }
});



