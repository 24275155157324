function productQuantityChange(event) {
  event.preventDefault();
  const input = this.closest('.js-number-selector').querySelector('.js-number-selector-input')
  if (this.classList.contains('js-number-selector-minus')) {
    if (input.value >= 2) {
      input.value = parseInt(input.value) - 1
    }
  }

  if (this.classList.contains('js-number-selector-plus')) {
    if (parseInt(input.value) < parseInt(input.max)) { input.value = parseInt(input.value) + 1 }
  }

  updateCartProductQuantity(this)
}

function productQuantityInputKeyPress(event) {
  event.preventDefault();
  productQuantityInputReset(this)
}

function productQuantityInputFocusOut(event) {
  event.preventDefault();
  productQuantityInputReset(this)

  updateCartProductQuantity(this)
}

function productQuantityInputReset(input) {
  if (input.value < parseInt(input.min)) { input.value = parseInt(input.min) }
  if (input.value > parseInt(input.max)) { input.value = parseInt(input.max) }
}

addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-number-selector-minus', 'click', productQuantityChange)
addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-number-selector-plus', 'click', productQuantityChange)
addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-number-selector-input', 'focusout', productQuantityInputFocusOut)
addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-number-selector-input', 'keyup', productQuantityInputKeyPress)
