window.closeGlobalOverlay = function () {
  const wrapper = document.querySelector(".header-catalog");
  const loginForm = document.querySelector("#js-login-form");
  let items = document.querySelectorAll(".header-catalog__selector.active, .js-global-overlay");

  loginForm.innerHTML = "";
  items.forEach(function(item) {
    item.classList.remove("active")
    item.classList.remove("global-overlay-login")
  });
  wrapper.classList.remove("active");
  document.querySelector("body").classList.remove('with-overlay');
}

window.openGlobalOverlay = function (login = false) {
  const overlay = document.querySelector(".js-global-overlay");
  overlay.classList.add(`active`);
  if (login === true) {
    document.querySelector("body").classList.add('with-overlay');
    overlay.classList.add('global-overlay-login')
  }
}

addMultiEventListenerByClass('DOMContentLoaded ajax:success',
  '.js-global-overlay', 'click', closeGlobalOverlay)