function toggleFilter(event) {
 event.preventDefault();
 const filter = document.querySelector('.js-catalog-filter');
 filter.classList.toggle("active");
}

function EventListener(className, event, fn) {
  const toggler = document.querySelector(className);
  if (toggler !== null) { toggler.addEventListener(event, fn, false); }
}

document.addEventListener("DOMContentLoaded", function() {
  EventListener('.js-catalog-filter-toggle', 'click', toggleFilter);
})