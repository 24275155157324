window.addEventListenerByClass = function(className, event, fn) {
  const list = document.querySelectorAll(className);
  list.forEach(function(item) {
    item.addEventListener(event, fn, false);
  });
}

window.addMultiEventListenerByClass = function(eventTypes, className, event, fn) {
  eventTypes.split(' ').forEach(function (eventType) {
    window.addEventListener(eventType, function() {
      addEventListenerByClass(className, event, fn)
    })
  })
}

window.fireJsRendered = function () {
  event = new Event('jsRendered');
  window.dispatchEvent(event);
}