document.addEventListener("DOMContentLoaded", function(event) {
  if (typeof ymaps === 'object') {
    const marker = document.querySelector("#js-yandex-map").dataset.marker;
    ymaps.ready(function () {
      const myMap = new ymaps.Map("js-yandex-map", {
        center: [56.930316, 60.610958],
        zoom: 14,
        controls: []
      });

      const placeMark = new ymaps.Placemark([56.930316,60.610958], {
        balloonContentHeader: 'Урал-Транс-Нефть<br><br>',
        balloonContentBody: 'г. Екатеринбург, пр. Космонавтов, 145, В<br><br>пн-пт 9:00-18:00, перерыв: без перерыва<br> Суббота, Воскресенье-Выходные дни<br> +7 (343) 346-84-56 (Отдел продаж)<br> +7 (967) 639-84-56<br> <a href="mailto:utneft@yandex.ru">utneft@yandex.ru</a>',
        hintContent: 'Урал-Транс-Нефть<br>г. Екатеринбург, пр. Космонавтов, 145, В'
      }, {
        iconLayout: 'default#image',
        iconImageHref: marker,
        iconImageSize: [36, 50],
        iconImageOffset: [-18, -50]
      });

      myMap.geoObjects.add(placeMark);

      myMap.controls.add('zoomControl', { position: { left: '5px', top: '5px'} });
      myMap.controls.add('typeSelector');
    });
  }
});
