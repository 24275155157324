showMoreText = function() {
  const button = document.querySelector('.js-show-more-button')
  const buttonWrapper = button.closest('.js-show-more');
  const readMoreBlock = buttonWrapper.querySelector('.product__show-more')

  readMoreBlock.classList.add('product__show-more-expanded');
  button.style.display = 'none';
};

chackMoreTextHeight = function() {
  const button = document.querySelector('.js-show-more-button')
  
  if (button) {
    const buttonWrapper = button.closest('.js-show-more');
    const readMoreBlock = buttonWrapper.querySelector('.product__show-more')
    
    if (readMoreBlock.offsetHeight > 197) {
      button.classList.remove('hidden');
    }
  }
}

addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-show-more-button', 'click', showMoreText);

document.addEventListener('DOMContentLoaded', chackMoreTextHeight);
window.addEventListener('jsRendered', chackMoreTextHeight);
