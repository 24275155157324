import { CountUp } from 'countup.js';

window.changeCartCounterValue = function (value) {
  const cartCounterSelector = document.querySelectorAll('.js-cart-products-count');

  cartCounterSelector.forEach(function(item) {
    item.innerHTML = value
  });
}

window.changeCartTotalCost = function (price) {
  const cartTotalCost = document.querySelector('.js-cart-total-cost');

  const countUpOptions = {
    startVal: cartTotalCost.dataset.total,
    duration: 1,
    separator: ' ',
    suffix: ' руб.',
  }
  const counter = new CountUp(cartTotalCost, price, countUpOptions)
  if (!counter.error) {
    counter.start();
  } else {
    console.error(counter.error);
  }

  cartTotalCost.dataset.total = price
}

window.updateCartProductQuantity = function (element) {
  const form = element.closest('form.cart-product__quantity');
  if (form !== null) {
    Rails.fire(form, 'submit');
  }
}

window.changeProductTotalCost = function (element, newQuantity) {
  const oldTotal = element.dataset.price * element.dataset.quantity
  const newTotal = element.dataset.price * newQuantity
  const countUpOptions = {
    startVal: oldTotal,
    duration: 1,
    separator: ' ',
    prefix: ' = ',
    suffix: ' руб.',
  }
  const counter = new CountUp(element, newTotal, countUpOptions)
  if (!counter.error) {
    counter.start();
  } else {
    console.error(counter.error);
  }

  element.dataset.quantity = newQuantity
}

window.cartPig = function (element) {
  const pig = element.querySelector(".js-cart-pig");
  let cart = '';
  if (document.querySelector(".header-sticky__cart").offsetHeight > 0) {
    cart = document.querySelector(".header-sticky__cart")
  } else {
    cart = document.querySelector('.header__cart');
  }
  let sX = element.getBoundingClientRect().left;
  let sY = element.getBoundingClientRect().bottom;
  let fX = cart.getBoundingClientRect().left;
  let fY = cart.getBoundingClientRect().top;
  let s = Math.sqrt(Math.pow(fX-sX,2) + Math.pow(fY-sY,2));
  let v = 1.5;
  let duration = parseInt(s/v);
  duration > 500 ? 500 : duration;
  pig.classList.add("active")
  pig.setAttribute("style", "left: " + sX + "px; top: " + sY + "px")
  pig.animate([{"top" : sY + 'px', "left" : sX + 'px'},
    {"top" : fY + 'px', "left" : fX + 'px'}], {duration: duration});
  setTimeout(removePig, duration, pig);
}

function removePig(pig) {
  pig.classList.remove("active");
  pig.setAttribute("style", "");
}