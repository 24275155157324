import tippy from 'tippy.js';

const tippyInit = function() {
  tippy(document.querySelectorAll('[data-tippy-content]'),
    {
      trigger: 'click',
      arrow: false,
      allowHTML: true,
      placement: 'auto-start',
      theme: 'light'
    });
}

document.addEventListener('DOMContentLoaded', tippyInit);
window.addEventListener('jsRendered', tippyInit);
