import noUiSlider from 'nouislider';

document.addEventListener("DOMContentLoaded", function(event) {
  const propertiesSliders = document.querySelectorAll(".js-properties-slider");
  
  for ( let i = 0; i < propertiesSliders.length; i++ ) {
    const propertiesSlider = propertiesSliders[i]
    const options = JSON.parse(propertiesSlider.dataset.options)
    const optionsForSlider = options.map((a) => a.value)
    const selectedCheckboxes = propertiesSlider.
      querySelectorAll('input[type=checkbox]:checked');
    
    let startValue
    let endValue
    
    if (selectedCheckboxes.length > 0) {
      startValue = selectedCheckboxes[0].dataset.value
      endValue = selectedCheckboxes[selectedCheckboxes.length-1].dataset.value
    } else {
      const checkboxes = propertiesSlider.
        querySelectorAll('input[type=checkbox]')
      startValue = checkboxes[0].dataset.value
      endValue = checkboxes[checkboxes.length-1].dataset.value
    }
    
    let format = {
      to: function(value) { return optionsForSlider[Math.round(value)]; },
      from: function (value) { return optionsForSlider.indexOf(value); }
    };
    
    noUiSlider.create(propertiesSlider, {
      start: [startValue, endValue],
      step: 1,
      tooltips: true,
      format: format,
      range: {
        min: 0,
        max: optionsForSlider.length - 1
      },
      pips: {
        mode: 'positions',
        format: format,
        values: [0, 50, 100],
      }
    });
      
    propertiesSlider.noUiSlider.on('update', function (values) {
      propertiesSlider.querySelectorAll('input[type=checkbox]').
        forEach(checkbox => checkbox.checked = false)
      
      let leftIndex = optionsForSlider.indexOf(values[0]);
      let rightIndex = optionsForSlider.indexOf(values[1])+1;
      
      options.slice(leftIndex, rightIndex).map((selected) => {
        let checkbox = document.getElementById('property_dictionary_option_'+selected.id)
        checkbox.checked = true;
      })
    })
  }
});
