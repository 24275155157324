import Rails from "@rails/ujs";
window.Rails = Rails;
if(Rails.fire(document, "rails:attachBindings")) { Rails.start(); }

import 'swiper/swiper.scss';
import 'nouislider/dist/nouislider.css';
import '../css/application.sass';
import '../javascripts/helpers/ResizeSensor'
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css'; // optional for styling

require.context('../fonts', true)
require.context('../images', true)

import "../javascripts/globalEventListener"
import "../javascripts/overlay"
import "../javascripts/category-menu"
import "../javascripts/sticky-header"
import "../javascripts/slider-init"
import "../javascripts/product-variant-switcher"
import "../javascripts/product-quantity-change"
import "../javascripts/categories-filter"
import "../javascripts/swiper-init"
import "../javascripts/company-map"
import "../javascripts/cart"
import "../javascripts/search"
import "../javascripts/yandex-mertika"
import '../javascripts/properties-slider-init'
import '../javascripts/tippy-init'
import '../javascripts/show-more'
