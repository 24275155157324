const SEARCH_INPUT_ID = "js-search-input";
const RESULTS_ID = "js-search-results";

const MIN_QUERY_LENGTH = 2;
const DEBOUNCE_DELAY = 300;

async function search() {
  const query = searchInput().value;
  if (query.length < MIN_QUERY_LENGTH) {
    return
  }

  const response = await fetch(`/products.json?query=${query}`);
  const json = await response.json();

  const resultsEl = document.getElementById(RESULTS_ID);
  if (json.products.length) {
    const items = json.products.map(productHtml).join('');
    resultsEl.innerHTML = `
      <div class="search-results__wrapper">
        <ul>
          ${items}
            <li class="search-results__all">
              <a href="/products?query=${query}">Все результаты</a>         
            </li>      
          </ul>
      </div>  
    `;
  } else {
    resultsEl.innerHTML = "";
  }
}

function productHtml(product) {
  return `
    <li>
      <a href="${product.url}">
        ${product.name}
      </a>
    </li>
  `;
}

function searchInput() {
  return document.getElementById(SEARCH_INPUT_ID);
}

document.addEventListener("DOMContentLoaded", function(event) {
  let keyTimeout = null;

  searchInput().addEventListener('keyup', function(event) {
    if (keyTimeout) {
      clearTimeout(keyTimeout);
    }
    keyTimeout = setTimeout(search, DEBOUNCE_DELAY);
  });
});
