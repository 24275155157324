function toggleCategoriesMenu(event){
  event.preventDefault();
  const frontPageWrapper = document.querySelector(".js-header-catalog-frontpage");
  const wrapper = document.querySelector(".header-catalog");
  let items = document.querySelectorAll(".header-catalog__selector");
  items.forEach(function(item) {
    item.classList.remove("active")
  });
  wrapper.classList.add("active");
  this.closest('.header-catalog__selector').classList.toggle("active");
  openGlobalOverlay();

  // if (window.innerWidth < 1600 || frontPageWrapper === null) {
  //   openGlobalOverlay();
  // }
}

function openCategoriesMenu() {
  const wrapper = document.querySelector(".js-header-catalog-frontpage");
  if (wrapper !== null) {
    let items = wrapper.querySelector(".header-catalog__selector");
    wrapper.classList.add("active");
    items.classList.add("active");
  }
}

function closeCategoriesMenu() {
  const wrapper = document.querySelector(".js-header-catalog-frontpage");
  if (wrapper !== null) {
    let items = wrapper.querySelectorAll(".header-catalog__selector");
    wrapper.classList.remove("active");
    items.forEach(function(item) {
      item.classList.remove("active")
    });
  }
}

function toggleCategoriesMenuItem(event){
  event.preventDefault();
  this.closest('.js-expandable').classList.toggle("expanded");
  let target = this.closest('.js-expandable-target')
  if (target !== null) {
    target.classList.toggle("expanded")
  }
}

document.addEventListener("DOMContentLoaded", function() {
  addEventListenerByClass('.js-menu-toggle', 'click', toggleCategoriesMenu);
  addEventListenerByClass('.js-expandable span.js-expandable-link', 'click', toggleCategoriesMenuItem);
})
